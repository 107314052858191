import React from 'react';
import PropTypes from 'prop-types';

import FooterTopSocials from './FooterTopSocials/FooterTopSocials';
import FooterTopNavigation from './FooterTopNavigation/FooterTopNavigation';
import FooterTopScroller from './FooterTopScroller/FooterTopScroller';

const FooterTop = ({ socials, footerNavigation }) => {
  return (
    <div className="footer-top">
      <FooterTopSocials socials={socials} />
      <FooterTopNavigation footerNavigation={footerNavigation} />
      <FooterTopScroller />
    </div>
  );
};

FooterTop.propTypes = {
  socials: PropTypes.array,
  footerNavigation: PropTypes.array,
};

export default FooterTop;
