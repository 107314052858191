import React from 'react';
// import { scroller } from 'react-scroll';

import Arrow from '../../../../../svg/arrow-top-left-black.svg';

const FooterTopScroller = () => {
  // function scrollTop() {
  //   scroller.scrollTo('app-container', {
  //     duration: 500,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //   });
  // }

  return (
    <div className="footer-top__scroller">
      <a href="#top-section" className="scroll-top" data-scroll-to>
        <span className="scroll-top__icon">
          <Arrow />
        </span>
        <span className="scroll-top__title">Scroll top</span>
      </a>
    </div>
  );
};

export default FooterTopScroller;
