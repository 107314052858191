export const navigationData = [
  { id: 1, title: 'Home', slug: '/' },
  { id: 2, title: 'Food Directors', slug: '/food-directors/' },
  { id: 3, title: 'Food Photographers', slug: '/food-photographers/' },
  { id: 4, title: 'Beauty Directors', slug: '/beauty-directors/' },
  { id: 5, title: 'Beauty Photographers', slug: '/beauty-photographers/' },
  { id: 6, title: 'Guest Directors', slug: '/guest-directors/' },
  { id: 7, title: 'About', slug: '/about/' },
  { id: 8, title: 'Contacts', slug: '/contacts/' },
];
