import React from 'react';
import PropTypes from 'prop-types';

import FooterTop from './FooterTop/FooterTop';
import FooterBottom from './FooterBottom/FooterBottom';

const Footer = ({ socials, footerNavigation, copyright }) => {

  return (
    <footer className="footer" data-scroll-section>
      <FooterTop socials={socials} footerNavigation={footerNavigation} />
      <FooterBottom copyright={copyright} />
    </footer>
  );
};

Footer.propTypes = {
  socials: PropTypes.array,
  footerNavigation: PropTypes.array,
  copyright: PropTypes.string,
};

export default Footer;
