import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import {
  LocomotiveScrollProvider,
} from 'react-locomotive-scroll';

import { footerStatic } from '../../db/footerStatic';

import { Header, Instagram, Footer } from './index';

const Layout = ({ children, theme }) => {

  const containerRef = React.useRef(null);

  return (
    <div className="app-container">
      <Header theme={theme} />
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          smoothMobile: false,
          getDirection: true,
          touchMultiplier: 2.5,
          // lerp: 0.1,
          firefoxMultiplier: 200,
          inertia: 0.3,
        }}
        watch={[]}
        containerRef={containerRef}
      >
        <StaticQuery
            query={graphql`
              query HeadingQuery {
                strapiContacts {
                  Socials {
                    title
                    link
                  }
                  instagram {
                    photo {
                      localFile {
                          childImageSharp {
                            gatsbyImageData(
                              width: 1400
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                            )
                        }
                      }
                    }
                  }
                }
              }
            `}
           render={data => (
            <div
              className="test-container"
              data-scroll-container
              ref={containerRef}
            >
              <main className="scroll-container">{children}</main>
              
              <Instagram photo={data.strapiContacts.instagram} />
  
              <Footer
                socials={data.strapiContacts.Socials}
                footerNavigation={footerStatic.footer_nav}
                copyright={footerStatic.copy}
              />
  
            </div>
          )}
          />
      </LocomotiveScrollProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;


