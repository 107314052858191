import React from 'react';
import PropTypes from 'prop-types';

const Burger = ({ menuToggle, open }) => {
  return (
    <button className={open ? 'burger is-open' : 'burger'} onClick={menuToggle}>
      <span className="burger__title-wrapper">
        <span className="burger__title">Menu</span>
        <span className="burger__title">Close</span>
      </span>
      <span className="burger__icon">
        <span className="burger__icon-line burger__icon-line__1"></span>
        <span className="burger__icon-line burger__icon-line__2"></span>
      </span>
    </button>
  );
};

Burger.propTypes = {
  menuToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default Burger;
