import React from 'react';
import PropTypes from 'prop-types';

import Copy from '../../../../svg/copy.svg';
import Nunox from '../../../../svg/nunox-logo.svg';

const FooterBottom = ({ copyright }) => {
  return (
    <div className="footer-bottom">
      <span className="footer-bottom__copy">
        <Copy />
        <p className="footer-bottom__copy-description">{copyright}</p>
      </span>
      <a
        href="https://nunox.co/"
        className="footer-bottom__create"
        target="blank"
      >
        <Nunox />
      </a>
    </div>
  );
};

FooterBottom.propTypes = {
  copyright: PropTypes.string,
};

export default FooterBottom;
