import React from 'react';
import PropTypes from 'prop-types';

import Arrow from '../../../../../svg/arrow-top-right-black.svg';

const FooterTopSocials = ({ socials }) => {
  return (
    <ul className="footer-top__socials">
      {socials.map((item, index) => {
        return (
          <li className="footer-top__socials-item" key={index}>
            <a href={item.link} className="socials-item__link" target="blank">
              <span className="socials-item__link-title">{item.title}</span>
              <span className="socials-item__link-icon">
                <Arrow />
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

FooterTopSocials.propTypes = {
  socials: PropTypes.array,
};

export default FooterTopSocials;
