import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import useScroll from '../../../hooks/useScroll';

import Burger from './Burger/Burger';
import Navigation from './Navigation/Navigation';
import LogoWhite from '../../../svg/logo-white.svg';
import LogoBlack from '../../../svg/logo-black.svg';

const Header = ({ theme }) => {
  const [open, setOpen] = React.useState(false);
  const [activeScroll, setActiveScroll] = React.useState(false);

  function menuToggle() {
    setOpen(!open);
  }

  const changePosition = 100;
  let scroller = useScroll();

  if (scroller > changePosition && !activeScroll) {
    setActiveScroll(true);
  }

  if (scroller <= changePosition && activeScroll) {
    setActiveScroll(false);
  }

  const logoDefault = <LogoWhite className="logo__white" />;

  const logoTheme =
    activeScroll || open ? (
      <>
        <LogoBlack className="contacts-logo__black is-hidden" />
        <LogoWhite className="contacts-logo__white" />
      </>
    ) : (
      <>
        <LogoBlack className="contacts-logo__black" />
        <LogoWhite className="contacts-logo__white is-hidden" />
      </>
    );

  return (
    <>
      <header className={activeScroll || open ? 'header is-active' : 'header'}>
        <div className="app-container">
          <div className="header__wrapper">
            <Link className="logo__link" to="/">
              {theme === 'black' ? logoTheme : logoDefault}
            </Link>
            <Burger menuToggle={menuToggle} open={open} />
          </div>
        </div>
      </header>
      <Navigation menuToggle={menuToggle} open={open} />
    </>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
