import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import InstaRounded from '../../../svg/instagram-round.svg';
import InstagramIcon from '../../../svg/instagram-icon.svg';

const Instagram = ({photo}) => {

  return (
    <section className="instagram" data-scroll-section>
      <div className="content-wrapper">
        <div className="instagram-wrapper">
          <div className="instagram-images">
            {photo.map((img, index) => {
              const image = getImage(img.photo.localFile);
              const count = ++index;
              return (
                <GatsbyImage image={image}
                  className={`instagram-image instagram-image__${count}`}
                  key={index}
                  alt="instagram"
                />
              );
            })}
          </div>
          <a href="https://www.instagram.com/zerotabletop/" className="instagram-link" target="blank">
            <span className="instagram-icon__wrapper">
              <InstaRounded className="instagram-rounded" />
              <InstagramIcon className="instagram-icon" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Instagram;
