import React from 'react';
import { Link } from 'gatsby';
import { gsap, Expo } from 'gsap';
import PropTypes from 'prop-types';

import { navigationData } from '../../../../db/navigationData';

const Navigation = ({ menuToggle, open }) => {
  const [tl] = React.useState(gsap.timeline({ paused: true }));

  let navLink = React.useRef([]);
  let nav = React.useRef(null);

  React.useEffect(() => {
    tl.to(nav, 0.7, { y: 0, ease: Expo.easeInOut })
      .staggerTo(
        navLink.current,
        0.5,
        { y: 0, opacity: 1, ease: Expo.easeInOut, delay: -0.5 },
        0.02
      )
      .reverse();
  }, [tl]);

  React.useEffect(() => {
    tl.reversed(!open);
  }, [open, tl]);

  return (
    <nav className="navigation" ref={(e) => (nav = e)}>
      <div className="navigation-wrapper">
        <ul className="menu">
          {navigationData.map((item, index) => {
            const count = ++index;
            return (
              <li
                className="menu__item"
                key={index}
                ref={(e) => (navLink.current[item.id] = e)}
              >
                <Link
                  to={item.slug}
                  className="menu__item-link"
                  onClick={menuToggle}
                >
                  <span className="menu__item-link__count">
                    {count <= 9 ? '0' + count + '.' : count + '.'}
                  </span>
                  <span className="menu__item-link__title">{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  navigationData: PropTypes.array,
};

export default Navigation;
