import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Iubenda from 'react-iubenda-policy'

const FooterTopNavigation = ({ footerNavigation }) => {
  const myPolicy = 78360841
  return (
    <ul className="footer-top__navigation">
      <li className="footer-top__navigation-item">
          <Iubenda id={myPolicy} type='privacy' styling='nostyle'>
            Privacy
          </Iubenda>
      </li>
      <li className="footer-top__navigation-item">
      <Iubenda id={myPolicy} type='cookie' styling='nostyle'>
        Cookie Policy
      </Iubenda>
      </li>
      {footerNavigation.map((item, index) => {
        return (
          <li className="footer-top__navigation-item" key={index}>
            <Link to={item.link} className="footer-top__navigation-item__link">
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

FooterTopNavigation.propTypes = {
  footerNavigation: PropTypes.array,
};

export default FooterTopNavigation;
